import React from 'react';
import { PersistState } from 'components/PersistState';
import { UrqlProvider } from './components/UrqlProvider';

type Props = {
  readonly element: React.ReactNode;
};

export const wrapPageElement = ({ element }: Props) => (
  <UrqlProvider>
    <PersistState />
    {element}
  </UrqlProvider>
);
