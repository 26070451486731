import { Store } from 'use-global-hook';
import { getQueryParams, remove, addUniq } from './utils';
import * as analytics from './analytics';

export type CartState = {
  readonly addedIds: number[];
  readonly quantityById: { [key: number]: number };
};

export type State = {
  readonly modal: null | string;
  readonly cart: CartState;
  readonly user: boolean;
  readonly token: string | null;
  readonly visibleCategoryIds: number[];
};

type CartIncreaseData = {
  rowId: number;
  counterInit: number;
  counterStep: number;
};

type CartDecreaseData = {
  rowId: number;
  counterStep: number;
};

export type Actions = {
  updateToken: (token: State['token']) => void;
  updateUser: (user: State['user']) => void;
  setVisibleCategoryState: (id: number, state: boolean) => void;
  setModal: (name: State['modal']) => void;
  restore: (data: { [key: string]: any }) => void;
  cart: {
    increaseQuantity: (data: CartIncreaseData & analytics.AddToCartData) => void;
    decreaseQuantity: (data: CartDecreaseData) => void;
    clear: () => void;
    removeItem: (rowId: number) => void;
  };
};

type RawData = {
  token?: string;
  cart?: { [key: string]: number };
};

type CartProduct = {
  rowId: number;
  price: number;
  oldPrice: number | null;
};

const initialCartState: CartState = {
  addedIds: [],
  quantityById: {},
};

const rawModal = getQueryParams().modal;

export const initialState: State = {
  modal: typeof rawModal === 'string' ? rawModal : null,
  token: null,
  user: false,
  cart: initialCartState,
  visibleCategoryIds: [],
};

const unpackCart = (data: { [key: string]: number }): CartState => {
  const addedIds = Object.keys(data).map(Number);
  const quantityById: CartState['quantityById'] = {};

  // у одного из клиентов всплыл ид без количества.
  addedIds.forEach((id) => {
    quantityById[id] = data[id] || 1;
  });

  return {
    quantityById,
    addedIds,
  };
};

export const getRestoredState = (data: RawData, passedState?: State): State => {
  const prev = passedState || initialState;

  return {
    ...prev,
    token: data.token || prev.token,
    cart: data.cart ? unpackCart(data.cart) : prev.cart,
  };
};
const getProductPrice = (p: CartProduct) => p.price || p.oldPrice || 1;

export const selectors = {
  cart: {
    getQuantityById: (s: State, rowId: number) => s.cart.quantityById[rowId] || 0,
    getItemSum: (s: State, products: CartProduct[]): number => {
      const addProductPrice = (sum: number, p: typeof products[0]) =>
        // добавил (s.cart.quantityById[p.rowId] || 0), чтобы при удалении продукта в корзине getItemSum не возвращал NaN,
        // т.к quantityById удаленного продукта будет undefined
        sum + getProductPrice(p) * (s.cart.quantityById[p.rowId] || 0);
      return products.reduce(addProductPrice, 0);
    },
    canPlaceOrder: (itemSum: number, minItemSum: number) => itemSum >= minItemSum,
  },
};

// actions
const changeCartQuantity = (state: CartState, id: number, value: number): CartState => ({
  addedIds: addUniq(state.addedIds, id),
  quantityById: {
    ...state.quantityById,
    [id]: value,
  },
});

const removeCartItem = (cart: CartState, rowId: number) => ({
  quantityById: remove(rowId, cart.quantityById),
  addedIds: cart.addedIds.filter((id) => id !== rowId),
});

export const actions = {
  setModal: (store: Store<State, Actions>, modal: State['modal']) => {
    store.setState({ ...store.state, modal });
  },
  updateToken: (store: Store<State, Actions>, token: State['token']) => {
    store.setState({ ...store.state, token });
  },
  setVisibleCategoryState: (store: Store<State, Actions>, id: number, visible: boolean) => {
    if (visible) {
      if (!store.state.visibleCategoryIds.includes(id)) {
        store.setState({ ...store.state, visibleCategoryIds: [...store.state.visibleCategoryIds, id] });
      }
    } else {
      store.setState({ ...store.state, visibleCategoryIds: store.state.visibleCategoryIds.filter((i) => i !== id) });
    }
  },
  updateUser: (store: Store<State, Actions>, user: State['user']) => {
    store.setState({ ...store.state, user });
  },
  restore: (store: Store<State, Actions>, data: RawData) => {
    store.setState(getRestoredState(data, store.state));
  },
  cart: {
    increaseQuantity: (store: Store<State, Actions>, data: CartIncreaseData & analytics.AddToCartData) => {
      const oldQuantity = selectors.cart.getQuantityById(store.state, data.rowId);
      const newQuantity = oldQuantity > 0 ? oldQuantity + data.counterStep : data.counterInit;

      store.setState({
        ...store.state,
        cart: changeCartQuantity(store.state.cart, data.rowId, newQuantity),
      });

      analytics.addToCart(data, newQuantity);
    },
    decreaseQuantity: (store: Store<State, Actions>, data: CartDecreaseData) => {
      const oldQuantity = selectors.cart.getQuantityById(store.state, data.rowId);

      let newQuantity = oldQuantity - data.counterStep;
      if (newQuantity < data.counterStep) {
        newQuantity = 0;
      }

      if (newQuantity === 0) {
        store.setState({
          ...store.state,
          cart: removeCartItem(store.state.cart, data.rowId),
        });
      } else {
        store.setState({
          ...store.state,
          cart: changeCartQuantity(store.state.cart, data.rowId, newQuantity),
        });
      }
    },
    clear: (store: Store<State, Actions>) => {
      store.setState({
        ...store.state,
        cart: initialCartState,
      });
    },
    removeItem: (store: Store<State, Actions>, rowId: number) => {
      store.setState({
        ...store.state,
        cart: removeCartItem(store.state.cart, rowId),
      });
    },
  },
};
