import { useEffect, useRef } from 'react';
import * as storage from 'storage';
import { useGlobal } from './hooks';
import { usePersistStateQuery } from '../__generated__/urql';

export const PersistState = () => {
  const [globalState, globalActions] = useGlobal();
  const skipNextSave = useRef(false);
  const [res, executeQuery] = usePersistStateQuery({
    variables: {
      token: globalState.token,
    },
  });

  useEffect(() => {
    const refetch = () => executeQuery({ requestPolicy: 'network-only' });
    const t = setInterval(refetch, 60000);
    return () => clearInterval(t);
  }, [executeQuery]);

  useEffect(() => {
    const { fetching, data, error } = res;

    if (fetching) {
      return;
    }

    if (data?.viewer.token) {
      globalActions.updateToken(data.viewer.token);
      globalActions.updateUser(!!data.viewer.user);
    }

    const tokenErrors = ['token is expired', 'signature is invalid'];

    if (error && tokenErrors.find((te) => error.message.includes(te))) {
      // eslint-disable-next-line
      console.log('reset invalid token', error.message);
      // попечаем, что следующий "тик" токена не должен сохраниться
      // чтобы не дергать другие вкладки
      skipNextSave.current = true;
      globalActions.updateToken(null);
    }
  }, [globalActions, res]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('token changed', globalState.token);

    // dont save null token to avoid load new token
    if (skipNextSave.current) {
      // eslint-disable-next-line no-console
      console.log('skip new token save');
      skipNextSave.current = false;
    } else {
      storage.setItem('token', globalState.token);
    }
  }, [globalState.token]);

  useEffect(() => {
    if (skipNextSave.current) {
      skipNextSave.current = false;
    } else {
      storage.setItem('cart', globalState.cart.quantityById);
    }
  }, [globalState.cart.quantityById]);

  useEffect(
    () =>
      storage.subscribe(['token', 'cart'], (key, value) => {
        // eslint-disable-next-line no-console
        console.log('catch new value', key, value);
        // вкладка, которая сохранила токен и так его сохранила,
        // дергать второй раз не нужно, иначе выйдет цикл
        skipNextSave.current = false;
        globalActions.restore({ [key]: value });
      }),
    [globalActions.restore],
  );

  return null;
};

export const dynamicQuery = /* urqlGraphQL */ `
  query PersistState($token: String) {
    viewer(token: $token) {
      token
      user {
        rowId
        buyedProductIds
      }
    }
  }
`;
