import React from 'react';
import { Provider, createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { requestPolicyExchange } from '@urql/exchange-request-policy';

const client = createClient({
  url: '/v5/graphql',
  exchanges: [
    dedupExchange,
    requestPolicyExchange({
      ttl: process.env.NODE_ENV === 'production' ? 300000 : 5000,
      shouldUpgrade: (operation) => operation.context.requestPolicy !== 'cache-only',
    }),
    cacheExchange,
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => err && !!err.networkError,
    }),
    fetchExchange,
  ],
});

export const UrqlProvider: React.FC = ({ children }) => <Provider value={client}>{children}</Provider>;
