/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import globalHook from 'use-global-hook';
import qs from 'query-string';
import { State, Actions, actions, getRestoredState } from 'store';
import * as storage from 'storage';

let queryState = {};

if (typeof window !== 'undefined') {
  try {
    const q = qs.parse(window.location.search);

    if (q.restoreState) {
      const { cart, token } = JSON.parse(q.restoreState as string);
      queryState = { cart, token };
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
}

const initialState = getRestoredState({
  token: storage.getItem('token'),
  cart: storage.getItem('cart'),
  ...queryState,
});

export const useGlobal = globalHook<State, Actions>(React, initialState, actions);
