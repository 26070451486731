/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { init } from '@sentry/browser';

import 'lazysizes';

init({
  dsn: 'https://e6f4a513801e4df980f7adf09b6a6c5b@sentry.io/1407591',
});

export { wrapPageElement } from './src/wrapPageElement';
