/* eslint-disable import/no-extraneous-dependencies */
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import parseISO from 'date-fns/parseISO';
import qs from 'query-string';
import * as Sentry from '@sentry/browser';

export const NBSP = '\xa0';

export const formatMoney = (value: number): string =>
  value
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    .replace(' ', NBSP)
    .replace(',00', '');

export const calcDiscount = (price: number, oldPrice: number | null) =>
  oldPrice && 100 - Math.round((price / oldPrice) * 100);

export const getNameAndWeight = (
  data: string,
  unit: string,
  weight: number | null,
): [string, string | null | undefined] => {
  let name = data;
  let w;

  if (!weight) {
    const match = data.match(/(.*?)\s*~\s*(.*?)$/);
    if (match) {
      [, name, w] = match;
    }
  } else if (unit.match(/^гр/)) {
    w = `${weight * 1000} г`;
  } else if (unit === 'шт') {
    w = null;
  } else {
    w = `${weight} ${unit}`;
  }

  return [name, w];
};

export const formatDay = (v: string): string => format(parseISO(v), 'd MMMM', { locale });

export const formatTime = (v: string): string => format(parseISO(v), 'HH:mm', { locale });

// форматирует время из количества секунд с начала суток
export const formatNumberTime = (v: number): string => {
  const minutes = Math.round(v / 60);
  return [Math.round(minutes / 60), minutes % 60].map((n) => n.toString().padStart(2, '0')).join(':');
};

export const getQueryParams = (): ReturnType<typeof qs.parse> => {
  if (typeof window !== 'undefined') {
    return qs.parse(window.location.search);
  }
  return {};
};

export const id = <T>(x: T) => x;

export const groupBy = <T>(data: readonly T[], fn: (v: any) => string = id) => {
  const groups: { [key: string]: T[] } = {};
  data.forEach((i) => {
    const k = fn(i);
    if (!groups[k]) {
      groups[k] = [];
    }
    groups[k].push(i);
  });
  return groups;
};

export const remove = <T, K extends keyof T>(key: K, obj: T): Omit<T, K> => {
  const { [key]: del, ...data } = obj;
  return data;
};

export function addUniq<T>(items: T[], value: T) {
  if (items.indexOf(value) !== -1) {
    return items;
  }

  return [...items, value];
}

export const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const safeJsonParse = (data: string) => {
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return null;
};

export const captureException = (e: Error) => {
  // eslint-disable-next-line no-console
  console.log('captureException', e);
  Sentry.captureException(e);
};

export const normalizePhone = (val: string) => {
  let nv = val.replace(/[^\d]/g, '');

  if (nv[0] === '8' && nv.length === 11) {
    nv = `7${nv.substr(1)}`;
  }

  return nv;
};

export const validateEmailOrPhone = (val: string) => {
  if (val.includes('@')) {
    return true;
  }

  const phone = normalizePhone(val);

  if (phone.length !== 11) {
    return 'Проверьте номер телефона. Количество цифр должно быть равно 11';
  }

  if (phone[0] !== '7') {
    return 'Проверьте номер телефона.';
  }

  return true;
};

export const validatePhone = (val: string) => {
  const phone = normalizePhone(val);

  if (phone.length !== 11) {
    return 'Проверьте номер телефона. Количество цифр должно быть равно 11';
  }

  if (phone[0] !== '7') {
    return 'Проверьте номер телефона.';
  }

  return true;
};

export const formatPhone = (val: string) => {
  const m = val.match(/7([\d]{3})([\d]{3})([\d]{2})([\d]{2})/);

  if (m) {
    return `+7 (${m[1]}) ${m[2]} ${m[3]} ${m[4]}`;
  }

  return val;
};

export const validateEmail = (val: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(val.toLowerCase());

  if (!valid) {
    return 'Введите email в корректном формате';
  }

  return true;
};
