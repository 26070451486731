import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Upload: any;
};

export type Address = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  city: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  house: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  entrance: Maybe<Scalars['String']>;
  building: Maybe<Scalars['String']>;
  buildingFloor: Maybe<Scalars['String']>;
  appartment: Maybe<Scalars['String']>;
  intercomCode: Maybe<Scalars['String']>;
  location: Maybe<Location>;
};

export type AddressInput = {
  city: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  house: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  entrance: Maybe<Scalars['String']>;
  building: Maybe<Scalars['String']>;
  buildingFloor: Maybe<Scalars['String']>;
  appartment: Maybe<Scalars['String']>;
  intercomCode: Maybe<Scalars['String']>;
  location: Maybe<LocationInput>;
};

export type AttachOrderAndSignInInput = {
  orderId: Scalars['Int'];
  code: Scalars['String'];
};

export type AttachOrderReviewImagePayload = OrderReviewImage | ErrorPayload;

export type Banner = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  title: Scalars['String'];
  intro: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  icon: Maybe<Scalars['String']>;
  navigate: Maybe<Scalars['String']>;
  webPath: Maybe<Scalars['String']>;
  mobileImageUrl: Scalars['String'];
  desktopImageUrl: Scalars['String'];
  target: Maybe<BannerTarget>;
};


export type BannerMobileImageUrlArgs = {
  width: Scalars['Int'];
};


export type BannerDesktopImageUrlArgs = {
  width: Scalars['Int'];
};

export type BannerTarget = Product | Category;

export type Block = {
  id: Scalars['ID'];
  name: Scalars['String'];
  data: Scalars['String'];
};

export type Cart = CartSummary & {
  id: Scalars['ID'];
  totalSum: Scalars['Float'];
  minItemSum: Scalars['Float'];
  itemSum: Scalars['Float'];
  deliveryCost: Scalars['Float'];
  comment: Maybe<Scalars['String']>;
  canPlaceOrder: Scalars['Boolean'];
  items: Array<CartItem>;
};

export type CartItem = {
  id: Scalars['ID'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
};

export type CartSummary = {
  itemSum: Scalars['Float'];
  deliveryCost: Scalars['Float'];
  totalSum: Scalars['Float'];
};

export type Category = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
  products: Array<Product>;
  productIds: Array<Scalars['Int']>;
  cardColor: Maybe<Scalars['String']>;
  cardIconUrl: Scalars['String'];
  /** @deprecated Use `cardIconUrl`. */
  iconUrl: Maybe<Scalars['String']>;
  pageUrl: Scalars['String'];
};


export type CategoryProductsArgs = {
  first: Maybe<Scalars['Int']>;
};


export type CategoryCardIconUrlArgs = {
  size: Scalars['Int'];
};


export type CategoryIconUrlArgs = {
  scale: Scalars['Float'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
};

export type ChangeProfileInput = {
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  activeAddressId: Maybe<Scalars['Int']>;
  dontSendMarketingNotifications: Maybe<Scalars['Boolean']>;
};

export type Courier = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  workPhone: Maybe<Scalars['String']>;
  car: Maybe<Scalars['String']>;
  isDriver: Scalars['Boolean'];
  location: Maybe<Location>;
};

export type CreateAddressOrErrorPayload = CreateAddressPayload | ErrorPayload;

export type CreateAddressPayload = {
  address: Address;
  viewer: Viewer;
};

export type CreateMessageInput = {
  phone: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  content: Scalars['String'];
};

export type DeleteAddressOrErrorPayload = DeleteAddressPayload | ErrorPayload;

export type DeleteAddressPayload = {
  deletedId: Scalars['ID'];
  viewer: Viewer;
};

export type DeliveryCost = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  itemSum: Scalars['Float'];
  value: Scalars['Float'];
  activeRange: Maybe<TimeRange>;
};

export type DeliveryInterval = {
  id: Scalars['ID'];
  date: Scalars['String'];
  value: Scalars['String'];
  prepareTime: Scalars['Int'];
  duration: Scalars['Int'];
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type DeliveryZone = {
  name: Scalars['String'];
  bound: Array<Location>;
  deliveryCosts: Array<DeliveryCost>;
  mapFillColor: Scalars['String'];
  mapStrokeColor: Scalars['String'];
};

export type DepositTransaction = {
  id: Scalars['ID'];
  createdAt: Maybe<Scalars['Time']>;
  value: Scalars['String'];
  stepValue: Scalars['String'];
  orderId: Maybe<Scalars['Int']>;
};

export type ErrorPayload = {
  message: Scalars['String'];
  field: Maybe<Scalars['String']>;
};

export type Location = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Mutation = {
  signIn: SessionOrErrorPayload;
  signInByFacebook: SessionOrErrorPayload;
  signInByGoogle: SessionOrErrorPayload;
  signInByYandex: SessionOrErrorPayload;
  signInByCode: SessionOrErrorPayload;
  requestSignInCode: Maybe<ErrorPayload>;
  signOut: Viewer;
  replaceCartItem: ReplaceCartItemPayload;
  clearCart: Cart;
  placeOrder: PlaceOrderOrErrorPayload;
  attachOrderAndSignIn: SessionOrErrorPayload;
  changePassword: Maybe<ErrorPayload>;
  changeProfile: ProfileOrErrorPayload;
  confirmPhone: ProfileOrErrorPayload;
  requestConfirmPhoneCode: Maybe<ErrorPayload>;
  updateOrderRating: Order;
  updateOrderReview: Order;
  attachOrderReviewImage: Maybe<AttachOrderReviewImagePayload>;
  removeOrderReviewImage: Maybe<OrderReviewImage>;
  createAddress: CreateAddressOrErrorPayload;
  deleteAddress: DeleteAddressOrErrorPayload;
  createMessage: Maybe<ErrorPayload>;
  subscribe: Maybe<ErrorPayload>;
  requestPaymentUrl: RequestPaymentUrlOrErrorPayload;
};


export type MutationSignInArgs = {
  token: Scalars['String'];
  input: SignInInput;
};


export type MutationSignInByFacebookArgs = {
  token: Scalars['String'];
  input: SignInByFacebookInput;
};


export type MutationSignInByGoogleArgs = {
  token: Scalars['String'];
  input: SignInByGoogleInput;
};


export type MutationSignInByYandexArgs = {
  token: Scalars['String'];
  input: SignInByYandexInput;
};


export type MutationSignInByCodeArgs = {
  token: Scalars['String'];
  input: SignInByCodeInput;
};


export type MutationRequestSignInCodeArgs = {
  token: Scalars['String'];
  input: RequestSignInCodeInput;
};


export type MutationReplaceCartItemArgs = {
  token: Scalars['String'];
  input: ReplaceCartItemInput;
};


export type MutationClearCartArgs = {
  token: Scalars['String'];
};


export type MutationPlaceOrderArgs = {
  token: Scalars['String'];
  clearCart: Maybe<Scalars['Boolean']>;
  input: PlaceOrderInput;
};


export type MutationAttachOrderAndSignInArgs = {
  input: AttachOrderAndSignInInput;
};


export type MutationChangePasswordArgs = {
  token: Scalars['String'];
  input: ChangePasswordInput;
};


export type MutationChangeProfileArgs = {
  token: Scalars['String'];
  input: ChangeProfileInput;
};


export type MutationConfirmPhoneArgs = {
  token: Scalars['String'];
  code: Scalars['String'];
};


export type MutationRequestConfirmPhoneCodeArgs = {
  token: Scalars['String'];
};


export type MutationUpdateOrderRatingArgs = {
  token: Scalars['String'];
  input: UpdateOrderRatingInput;
};


export type MutationUpdateOrderReviewArgs = {
  token: Scalars['String'];
  input: UpdateOrderReviewInput;
};


export type MutationAttachOrderReviewImageArgs = {
  token: Scalars['String'];
  orderId: Scalars['Int'];
};


export type MutationRemoveOrderReviewImageArgs = {
  token: Scalars['String'];
  rowId: Scalars['Int'];
};


export type MutationCreateAddressArgs = {
  token: Scalars['String'];
  input: AddressInput;
};


export type MutationDeleteAddressArgs = {
  token: Scalars['String'];
  rowId: Scalars['Int'];
};


export type MutationCreateMessageArgs = {
  token: Maybe<Scalars['String']>;
  input: CreateMessageInput;
};


export type MutationSubscribeArgs = {
  token: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type MutationRequestPaymentUrlArgs = {
  input: RequestPaymentUrlInput;
};

export type Order = CartSummary & {
  rowId: Scalars['Int'];
  shopId: Scalars['Int'];
  id: Scalars['ID'];
  deliveryAt: Maybe<Scalars['Time']>;
  deliveryLocation: Maybe<Location>;
  deliveredAt: Maybe<Scalars['Time']>;
  itemSum: Scalars['Float'];
  deliveryCost: Scalars['Float'];
  discount: Scalars['Float'];
  totalSumMultiplier: Scalars['Float'];
  percentDiscount: Scalars['Float'];
  totalSum: Scalars['Float'];
  onlinePaymentUrl: Scalars['String'];
  yandexKassaConfirmationToken: Scalars['String'];
  paymentMethod: PaymentMethod;
  status: OrderStatus;
  asap: Scalars['Boolean'];
  withoutOverweight: Scalars['Boolean'];
  ecoPack: Scalars['Boolean'];
  items: Array<OrderItem>;
  rating: Maybe<Scalars['Int']>;
  review: Maybe<Scalars['String']>;
  reviewImages: Array<OrderReviewImage>;
  placeData: Maybe<ShopOrder>;
  courier: Maybe<Courier>;
  address: Maybe<Address>;
};

export type OrderItem = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  productId: Maybe<Scalars['Int']>;
  product: Product;
  orderedQuantity: Scalars['Float'];
  quantity: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  priceMultiplier: Scalars['Float'];
  percentDiscount: Scalars['Float'];
};

export type OrderReviewImage = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  url: Scalars['String'];
  putUrl: Maybe<Scalars['String']>;
};

export enum OrderStatus {
  PENDING = 'PENDING',
  PACKING = 'PACKING',
  SHIPPED = 'SHIPPED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DELIVERED = 'DELIVERED'
}

export type Payment = {
  id: Scalars['String'];
  amount: Scalars['Float'];
  status: PaymentStatus;
  successful: Scalars['Boolean'];
};

export enum PaymentMethod {
  BY_CASH = 'BY_CASH',
  BY_CARD = 'BY_CARD',
  ONLINE = 'ONLINE',
  DEPOSIT = 'DEPOSIT',
  EMPLOYEE = 'EMPLOYEE'
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PRE_AUTH = 'PRE_AUTH',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  REFUND = 'REFUND',
  AUTH_ACS = 'AUTH_ACS',
  FAILED = 'FAILED'
}

export type PlaceOrderInput = {
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  deliveryAt: Scalars['String'];
  asap: Scalars['Boolean'];
  withoutOverweight: Scalars['Boolean'];
  ecoPack: Scalars['Boolean'];
  origin: Scalars['String'];
  newAddress: Maybe<AddressInput>;
  addressId: Maybe<Scalars['Int']>;
  items: Maybe<Array<PlaceOrderItemInput>>;
};

export type PlaceOrderItemInput = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type PlaceOrderOrErrorPayload = PlaceOrderPayload | ErrorPayload;

export type PlaceOrderPayload = {
  viewer: Viewer;
  orderId: Scalars['Int'];
  authCodeSended: Scalars['Boolean'];
  onlinePaymentUrl: Scalars['String'];
  yandexKassaConfirmationToken: Scalars['String'];
  yandexTurboPaymentToken: Maybe<Scalars['String']>;
  totalSum: Scalars['Float'];
  deliveryCost: Scalars['Float'];
  order: ShopOrder;
};

export type Product = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  pageUrl: Scalars['String'];
  description: Maybe<Scalars['String']>;
  status: ProductStatus;
  price: Scalars['Int'];
  unit: Scalars['String'];
  weight: Maybe<Scalars['Float']>;
  stockId: Scalars['Int'];
  stockUnit: Scalars['String'];
  /** Если старая цена существует, то она гарантированно меньше текущей */
  oldPrice: Maybe<Scalars['Int']>;
  /** @deprecated use mainImageUrl */
  mainImage: Scalars['String'];
  mainImageUrl: Scalars['String'];
  seasonText: Maybe<Scalars['String']>;
  seasonColor: Maybe<Scalars['String']>;
  textBadge: Maybe<Scalars['String']>;
  lqip: Maybe<Scalars['String']>;
  story: Maybe<Scalars['String']>;
  categories: Array<Category>;
  maxQuantityPerOrder: Maybe<Scalars['Int']>;
  composition: Maybe<Scalars['String']>;
  nutritionalValue: Maybe<Scalars['String']>;
  storageRequirements: Maybe<Scalars['String']>;
  expiresIn: Maybe<Scalars['String']>;
  vendorCountry: Maybe<Scalars['String']>;
  counterInit: Scalars['Int'];
  counterStep: Scalars['Int'];
};


export type ProductMainImageArgs = {
  size: Scalars['Int'];
};


export type ProductMainImageUrlArgs = {
  size: Scalars['Int'];
};

export enum ProductStatus {
  ARCHIVE = 'ARCHIVE',
  HIDDEN = 'HIDDEN',
  BY_REQUEST = 'BY_REQUEST',
  VISIBLE = 'VISIBLE'
}

export type ProfileOrErrorPayload = User | ErrorPayload;

export type Query = {
  currentRegion: Region;
  enabledFeatures: Array<Scalars['String']>;
  recipes: Array<Recipe>;
  blocks: Array<Block>;
  block: Maybe<Block>;
  banners: Array<Banner>;
  categories: Array<Category>;
  reviews: Array<Review>;
  onSale: Category;
  category: Maybe<Category>;
  deliveryCosts: Array<DeliveryCost>;
  deliveryZones: Array<DeliveryZone>;
  deliveryIntervals: Array<DeliveryInterval>;
  now: Scalars['String'];
  products: Array<Product>;
  productIds: Array<Scalars['Int']>;
  productsByIds: Array<Product>;
  product: Maybe<Product>;
  viewer: Viewer;
};


export type QueryRecipesArgs = {
  limit: Maybe<Scalars['Int']>;
};


export type QueryBlockArgs = {
  name: Scalars['String'];
};


export type QueryBannersArgs = {
  limit: Maybe<Scalars['Int']>;
  origin: Maybe<Scalars['String']>;
};


export type QueryReviewsArgs = {
  first: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  rowId: Scalars['Int'];
};


export type QueryProductsArgs = {
  search: Maybe<Scalars['String']>;
};


export type QueryProductsByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryProductArgs = {
  rowId: Scalars['Int'];
};


export type QueryViewerArgs = {
  token: Maybe<Scalars['String']>;
};

export type Recipe = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  tags: Maybe<Array<Scalars['String']>>;
  ingridients: Array<RecipeIngridient>;
  steps: Array<RecipeStep>;
  imageUrl: Scalars['String'];
};


export type RecipeImageUrlArgs = {
  size: Scalars['Int'];
};

export type RecipeIngridient = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type RecipeStep = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  description: Scalars['String'];
  imageUrl: Scalars['String'];
};


export type RecipeStepImageUrlArgs = {
  size: Scalars['Int'];
};

export type Region = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ReplaceCartItemInput = {
  productId: Scalars['Int'];
  increaseQuantity: Scalars['Int'];
};

export type ReplaceCartItemPayload = {
  item: Maybe<CartItem>;
  viewer: Viewer;
};

export type RequestPaymentUrlInput = {
  orderId: Scalars['Int'];
  useAutoPayment: Scalars['Boolean'];
  rebind: Scalars['Boolean'];
};

export type RequestPaymentUrlOrErrorPayload = RequestPaymentUrlPayload | ErrorPayload;

export type RequestPaymentUrlPayload = {
  url: Maybe<Scalars['String']>;
  bindedPan: Maybe<Scalars['String']>;
  iframeHeight: Scalars['Int'];
  payment: Payment;
};

export type RequestSignInCodeInput = {
  phone: Scalars['String'];
};

export type Review = {
  id: Scalars['ID'];
  rowId: Scalars['Int'];
  author: Scalars['String'];
  url: Scalars['String'];
  mainImageUrl: Scalars['String'];
};


export type ReviewMainImageUrlArgs = {
  size: Scalars['Int'];
};

export type SessionOrErrorPayload = SignPayload | ErrorPayload;

export type ShopOrder = {
  address: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  comment: Maybe<Scalars['String']>;
  items: Array<ShopOrderItem>;
};

export type ShopOrderItem = {
  quantity: Scalars['Float'];
  product: Product;
};

export type SignInByCodeInput = {
  phone: Scalars['String'];
  code: Scalars['String'];
};

export type SignInByFacebookInput = {
  accessToken: Scalars['String'];
};

export type SignInByGoogleInput = {
  idToken: Scalars['String'];
};

export type SignInByYandexInput = {
  token: Scalars['String'];
};

export type SignInInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type SignPayload = {
  viewer: Viewer;
  token: Scalars['String'];
};


export type TimeRange = {
  from: Scalars['Time'];
  to: Scalars['Time'];
};

export type UpdateOrderRatingInput = {
  orderId: Scalars['Int'];
  value: Scalars['Int'];
};

export type UpdateOrderReviewInput = {
  orderId: Scalars['Int'];
  value: Scalars['String'];
};


export type User = {
  rowId: Scalars['Int'];
  id: Scalars['ID'];
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Maybe<Scalars['String']>;
  mainPhone: Maybe<Scalars['String']>;
  mainPhoneConfirmed: Maybe<Scalars['Boolean']>;
  dontSendMarketingNotifications: Scalars['Boolean'];
  orders: Array<Order>;
  depositBalance: Scalars['Float'];
  depositTransactions: Array<DepositTransaction>;
  addresses: Array<Address>;
  orderCount: Scalars['Int'];
  buyedProductIds: Array<Scalars['Int']>;
  buyedProducts: Array<Product>;
  lastOrder: Maybe<Order>;
  activeAddress: Maybe<Address>;
  expressDeliveryInterval: Maybe<Scalars['Int']>;
};


export type UserOrdersArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type Viewer = {
  id: Scalars['String'];
  role: ViewerRole;
  token: Scalars['String'];
  user: Maybe<User>;
  recommendations: Category;
  cart: Cart;
};


export type ViewerCartArgs = {
  deliveryAt: Maybe<Scalars['String']>;
};

export enum ViewerRole {
  GUEST = 'GUEST',
  CUSTOMER = 'CUSTOMER',
  DEVELOPER = 'DEVELOPER',
  EMPLOYEE = 'EMPLOYEE'
}

export type ActiveAddress_DataFragment = { activeAddress: Maybe<(
    Pick<Address, 'rowId'>
    & Address_DataFragment
  )>, addresses: Array<Pick<Address, 'rowId'>> };

export type Address_DataFragment = Pick<Address, 'id' | 'rowId' | 'deleted' | 'city' | 'street' | 'house' | 'comment' | 'entrance' | 'building' | 'buildingFloor' | 'appartment' | 'intercomCode'>;

export type AsyncCatalogQueryVariables = Exact<{ [key: string]: never; }>;


export type AsyncCatalogQuery = { categories: Array<Pick<Category, 'rowId'>> };

export type AsyncCatalogContentQueryVariables = Exact<{ [key: string]: never; }>;


export type AsyncCatalogContentQuery = { categories: Array<(
    Pick<Category, 'rowId'>
    & CategoryRow_DataFragment
  )> };

export type AuthViewerQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type AuthViewerQuery = { viewer: { user: Maybe<My_DataFragment> } };

export type AuthSignInMutationVariables = Exact<{
  token: Scalars['String'];
  input: SignInInput;
}>;


export type AuthSignInMutation = { result: (
    { __typename: 'SignPayload' }
    & Pick<SignPayload, 'token'>
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type Banner_DataFragment = (
  Pick<Banner, 'title' | 'desktopImageUrl' | 'icon' | 'intro' | 'description' | 'webPath' | 'navigate'>
  & { target: Maybe<(
    { __typename: 'Product' }
    & Pick<Product, 'vendorCountry'>
    & CartCounter_DataFragment
  ) | { __typename: 'Category' }> }
);

export type BannersQueryVariables = Exact<{ [key: string]: never; }>;


export type BannersQuery = { banners: Array<(
    Pick<Banner, 'rowId'>
    & Banner_DataFragment
  )> };

export type CartCounter_DataFragment = Pick<Product, 'rowId' | 'price' | 'oldPrice' | 'unit' | 'weight' | 'status' | 'maxQuantityPerOrder' | 'counterInit' | 'counterStep'>;

export type CartItemCounter_DataFragment = Pick<Product, 'rowId' | 'price' | 'oldPrice' | 'unit' | 'weight' | 'status' | 'maxQuantityPerOrder' | 'counterInit' | 'counterStep'>;

export type CartSummary_DataFragment = (
  Pick<DeliveryCost, 'value' | 'itemSum'>
  & { activeRange: Maybe<Pick<TimeRange, 'from' | 'to'>> }
);

export type CategoryRow_DataFragment = (
  Pick<Category, 'name' | 'pageUrl'>
  & { products: Array<(
    Pick<Product, 'rowId'>
    & ProductCard_DataFragment
  )> }
);

export type ClosestDeliveryQueryVariables = Exact<{ [key: string]: never; }>;


export type ClosestDeliveryQuery = (
  Pick<Query, 'now'>
  & { deliveryIntervals: Array<Pick<DeliveryInterval, 'value' | 'from' | 'to'>> }
);

export type DeliveryCostsQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryCostsQuery = { deliveryZones: Array<(
    Pick<DeliveryZone, 'mapFillColor' | 'mapStrokeColor'>
    & { deliveryCosts: Array<Pick<DeliveryCost, 'value'>> }
  )>, deliveryCosts: Array<Pick<DeliveryCost, 'itemSum'>> };

export type DeliveryMapQueryVariables = Exact<{ [key: string]: never; }>;


export type DeliveryMapQuery = { deliveryZones: Array<(
    Pick<DeliveryZone, 'mapFillColor' | 'mapStrokeColor'>
    & { bound: Array<Pick<Location, 'lat' | 'lng'>>, deliveryCosts: Array<Pick<DeliveryCost, 'itemSum' | 'value'>> }
  )> };

export type DeliveryTimePicker_IntervalFragment = Pick<DeliveryInterval, 'id' | 'from' | 'to' | 'value'>;

export type DepositTransaction_DataFragment = Pick<DepositTransaction, 'orderId' | 'value' | 'stepValue' | 'createdAt'>;

export type HeaderCategorySelectorQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderCategorySelectorQuery = { categories: Array<Pick<Category, 'rowId' | 'pageUrl' | 'name'>> };

export type HomeCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeCategoriesQuery = { categories: Array<(
    Pick<Category, 'rowId'>
    & CategoryRow_DataFragment
  )> };

export type HomeReviewsQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeReviewsQuery = { reviews: Array<(
    Pick<Review, 'rowId'>
    & Review_DataFragment
  )> };

export type MessageFormMutationVariables = Exact<{
  token: Maybe<Scalars['String']>;
  input: CreateMessageInput;
}>;


export type MessageFormMutation = { result: Maybe<Pick<ErrorPayload, 'message'>> };

export type MyAddressItem_DataFragment = Address_DataFragment;

export type MyAddresses_DataFragment = { activeAddress: Maybe<Pick<Address, 'rowId'>>, addresses: Array<(
    Pick<Address, 'id' | 'rowId'>
    & MyAddressItem_DataFragment
  )> };

export type MyAddressesUpdateProfileMutationVariables = Exact<{
  token: Scalars['String'];
  input: ChangeProfileInput;
}>;


export type MyAddressesUpdateProfileMutation = { result: (
    { __typename: 'User' }
    & Pick<User, 'rowId' | 'email' | 'firstName' | 'lastName' | 'mainPhone' | 'depositBalance'>
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type MyAddressesDeleteAddressMutationVariables = Exact<{
  token: Scalars['String'];
  rowId: Scalars['Int'];
}>;


export type MyAddressesDeleteAddressMutation = { deleteAddress: (
    { __typename: 'DeleteAddressPayload' }
    & { viewer: { user: Maybe<(
        Pick<User, 'id'>
        & { activeAddress: Maybe<Pick<Address, 'rowId'>>, addresses: Array<MyAddressItem_DataFragment> }
      )> } }
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type MyDeposit_DataFragment = (
  Pick<User, 'depositBalance'>
  & { depositTransactions: Array<DepositTransaction_DataFragment> }
);

export type MyHistoryQueryVariables = Exact<{
  token: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type MyHistoryQuery = { viewer: { user: Maybe<(
      Pick<User, 'orderCount'>
      & { orders: Array<(
        Pick<Order, 'id'>
        & MyHistoryOrder_DataFragment
      )> }
    )> } };

export type MyHistoryOrder_DataFragment = (
  Pick<Order, 'deliveryAt' | 'deliveredAt' | 'status' | 'shopId' | 'asap' | 'deliveryCost' | 'itemSum' | 'totalSum'>
  & { items: Array<{ product: (
      Pick<Product, 'rowId' | 'counterInit' | 'counterStep' | 'price' | 'status'>
      & { imageUrl: Product['mainImage'], retinaImageUrl: Product['mainImage'] }
    ) }> }
  & OrderDetails_DataFragment
);

export type MyNewAddressMutationVariables = Exact<{
  token: Scalars['String'];
  input: AddressInput;
}>;


export type MyNewAddressMutation = { result: (
    { __typename: 'CreateAddressPayload' }
    & { address: Pick<Address, 'id'>, viewer: { user: Maybe<(
        Pick<User, 'id'>
        & { activeAddress: Maybe<Address_DataFragment>, addresses: Array<MyAddressItem_DataFragment> }
      )> } }
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type MyPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  input: ChangePasswordInput;
}>;


export type MyPasswordMutation = { result: Maybe<(
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  )> };

export type MyProfile_DataFragment = Pick<User, 'firstName' | 'lastName' | 'mainPhone' | 'email'>;

export type MyProfileMutationVariables = Exact<{
  token: Scalars['String'];
  input: ChangeProfileInput;
}>;


export type MyProfileMutation = { result: (
    { __typename: 'User' }
    & MyProfile_DataFragment
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type OrderDetails_DataFragment = { items: Array<(
    Pick<OrderItem, 'productId' | 'orderedQuantity' | 'quantity' | 'price'>
    & { product: Pick<Product, 'name' | 'stockUnit'> }
  )> };

export type PersistStateQueryVariables = Exact<{
  token: Maybe<Scalars['String']>;
}>;


export type PersistStateQuery = { viewer: (
    Pick<Viewer, 'token'>
    & { user: Maybe<Pick<User, 'rowId' | 'buyedProductIds'>> }
  ) };

export type ProductCard_DataFragment = (
  Pick<Product, 'name' | 'unit' | 'weight' | 'pageUrl' | 'price' | 'oldPrice'>
  & { normalImage: Product['mainImageUrl'], retinaImage: Product['mainImageUrl'] }
  & CartItemCounter_DataFragment
);

export type ProductListItem_DataFragment = (
  Pick<Product, 'name' | 'unit' | 'weight' | 'pageUrl' | 'price' | 'oldPrice'>
  & { imageUrl: Product['mainImageUrl'], retinaImageUrl: Product['mainImageUrl'] }
  & CartCounter_DataFragment
);

export type RecommendationsQueryVariables = Exact<{ [key: string]: never; }>;


export type RecommendationsQuery = { viewer: { recommendations: CategoryRow_DataFragment } };

export type Review_DataFragment = (
  Pick<Review, 'author' | 'url'>
  & { imageUrl: Review['mainImageUrl'], retinaImageUrl: Review['mainImageUrl'] }
);

export type Unnamed_1_QueryVariables = Exact<{ [key: string]: never; }>;


export type Unnamed_1_Query = {};

export type SaleQueryVariables = Exact<{ [key: string]: never; }>;


export type SaleQuery = { onSale: CategoryRow_DataFragment };

export type SubscribeFormMutationVariables = Exact<{
  token: Maybe<Scalars['String']>;
  email: Scalars['String'];
}>;


export type SubscribeFormMutation = { result: Maybe<Pick<ErrorPayload, 'message'>> };

export type CartQueryVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type CartQuery = { products: Array<(
    Pick<Product, 'rowId' | 'price' | 'oldPrice'>
    & ProductListItem_DataFragment
  )>, deliveryCosts: Array<(
    Pick<DeliveryCost, 'itemSum'>
    & CartSummary_DataFragment
  )> };

export type CheckoutQueryVariables = Exact<{
  token: Maybe<Scalars['String']>;
  ids: Array<Scalars['Int']>;
}>;


export type CheckoutQuery = { viewer: (
    Pick<Viewer, 'token'>
    & { user: Maybe<(
      Pick<User, 'depositBalance' | 'mainPhone'>
      & { lastOrder: Maybe<Pick<Order, 'ecoPack' | 'withoutOverweight' | 'asap' | 'paymentMethod'>> }
      & ActiveAddress_DataFragment
    )> }
  ), products: Array<Pick<Product, 'rowId' | 'price' | 'oldPrice'>>, deliveryIntervals: Array<(
    Pick<DeliveryInterval, 'value'>
    & DeliveryTimePicker_IntervalFragment
  )>, deliveryCosts: Array<(
    Pick<DeliveryCost, 'itemSum'>
    & CartSummary_DataFragment
  )> };

export type CheckoutMutationVariables = Exact<{
  token: Scalars['String'];
  input: PlaceOrderInput;
  yandex: Scalars['Boolean'];
  clearCart: Maybe<Scalars['Boolean']>;
}>;


export type CheckoutMutation = { placeOrder: (
    { __typename: 'PlaceOrderPayload' }
    & Pick<PlaceOrderPayload, 'orderId' | 'onlinePaymentUrl' | 'authCodeSended' | 'totalSum' | 'yandexKassaConfirmationToken'>
    & { viewer: (
      Pick<Viewer, 'token'>
      & { user: Maybe<(
        Pick<User, 'depositBalance' | 'mainPhone'>
        & { lastOrder: Maybe<Pick<Order, 'ecoPack' | 'withoutOverweight' | 'asap' | 'paymentMethod'>> }
        & ActiveAddress_DataFragment
      )> }
    ) }
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type CheckoutFinishModalMutationVariables = Exact<{
  input: AttachOrderAndSignInInput;
}>;


export type CheckoutFinishModalMutation = { attachOrderAndSignIn: (
    { __typename: 'SignPayload' }
    & Pick<SignPayload, 'token'>
    & { viewer: (
      Pick<Viewer, 'token'>
      & { user: Maybe<(
        Pick<User, 'depositBalance' | 'mainPhone'>
        & { lastOrder: Maybe<Pick<Order, 'ecoPack' | 'withoutOverweight' | 'asap' | 'paymentMethod'>> }
        & ActiveAddress_DataFragment
      )> }
    ) }
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type ForgotPasswordRequestSignInCodeMutationVariables = Exact<{
  token: Scalars['String'];
  input: RequestSignInCodeInput;
}>;


export type ForgotPasswordRequestSignInCodeMutation = { result: Maybe<(
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  )> };

export type ForgotPasswordSignInByCodeMutationVariables = Exact<{
  token: Scalars['String'];
  input: SignInByCodeInput;
}>;


export type ForgotPasswordSignInByCodeMutation = { result: (
    { __typename: 'SignPayload' }
    & Pick<SignPayload, 'token'>
  ) | (
    { __typename: 'ErrorPayload' }
    & Pick<ErrorPayload, 'message'>
  ) };

export type My_DataFragment = (
  Pick<User, 'firstName' | 'lastName' | 'mainPhone' | 'depositBalance' | 'email'>
  & MyDeposit_DataFragment
  & MyProfile_DataFragment
  & MyAddresses_DataFragment
  & ActiveAddress_DataFragment
);

export type MySignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type MySignOutMutation = { signOut: Pick<Viewer, 'token'> };

export type ProductsPageQueryVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type ProductsPageQuery = { productsByIds: Array<(
    Pick<Product, 'rowId'>
    & ProductCard_DataFragment
  )> };

export type RecommendationsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type RecommendationsPageQuery = { viewer: { recommendations: { products: Array<ProductCard_DataFragment> } } };

export type ReviewsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewsPageQuery = { reviews: Array<(
    Pick<Review, 'rowId'>
    & Review_DataFragment
  )> };

export type SalePageQueryVariables = Exact<{ [key: string]: never; }>;


export type SalePageQuery = { onSale: { products: Array<ProductCard_DataFragment> } };

export type SearchQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type SearchQuery = { products: Array<(
    Pick<Product, 'rowId'>
    & ProductCard_DataFragment
  )> };

export type BlockPageQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type BlockPageQuery = { block: Maybe<Pick<Block, 'name' | 'data'>> };

export type CategoryPageQueryVariables = Exact<{
  rowId: Scalars['Int'];
}>;


export type CategoryPageQuery = { category: Maybe<(
    Pick<Category, 'rowId' | 'name'>
    & { products: Array<(
      Pick<Product, 'rowId'>
      & ProductCard_DataFragment
    )> }
  )> };

export type ProductPageQueryVariables = Exact<{
  rowId: Scalars['Int'];
}>;


export type ProductPageQuery = { product: Maybe<(
    Pick<Product, 'name' | 'unit' | 'weight' | 'price' | 'oldPrice' | 'composition' | 'vendorCountry' | 'expiresIn' | 'storageRequirements'>
    & { imageUrl: Product['mainImageUrl'], retinaImageUrl: Product['mainImageUrl'] }
    & { categories: Array<Pick<Category, 'name' | 'pageUrl'>> }
    & CartCounter_DataFragment
  )> };

export const CartCounter_DataFragmentDoc = gql`
    fragment CartCounter_data on Product {
  rowId
  price
  oldPrice
  unit
  weight
  status
  maxQuantityPerOrder
  counterInit
  counterStep
}
    `;
export const Banner_DataFragmentDoc = gql`
    fragment Banner_data on Banner {
  title
  desktopImageUrl(width: 1440)
  icon
  intro
  title
  description
  webPath
  navigate
  target {
    __typename
    ... on Product {
      vendorCountry
      ...CartCounter_data
    }
  }
}
    ${CartCounter_DataFragmentDoc}`;
export const CartSummary_DataFragmentDoc = gql`
    fragment CartSummary_data on DeliveryCost {
  value
  itemSum
  activeRange {
    from
    to
  }
}
    `;
export const CartItemCounter_DataFragmentDoc = gql`
    fragment CartItemCounter_data on Product {
  rowId
  price
  oldPrice
  unit
  weight
  status
  maxQuantityPerOrder
  counterInit
  counterStep
}
    `;
export const ProductCard_DataFragmentDoc = gql`
    fragment ProductCard_data on Product {
  normalImage: mainImageUrl(size: 264)
  retinaImage: mainImageUrl(size: 528)
  name
  unit
  weight
  pageUrl
  price
  oldPrice
  ...CartItemCounter_data
}
    ${CartItemCounter_DataFragmentDoc}`;
export const CategoryRow_DataFragmentDoc = gql`
    fragment CategoryRow_data on Category {
  name
  pageUrl
  products(first: 15) {
    rowId
    ...ProductCard_data
  }
}
    ${ProductCard_DataFragmentDoc}`;
export const DeliveryTimePicker_IntervalFragmentDoc = gql`
    fragment DeliveryTimePicker_interval on DeliveryInterval {
  id
  from
  to
  value
}
    `;
export const OrderDetails_DataFragmentDoc = gql`
    fragment OrderDetails_data on Order {
  items {
    productId
    orderedQuantity
    quantity
    price
    product {
      name
      stockUnit
    }
  }
}
    `;
export const MyHistoryOrder_DataFragmentDoc = gql`
    fragment MyHistoryOrder_data on Order {
  deliveryAt
  deliveredAt
  status
  shopId
  asap
  deliveryCost
  itemSum
  totalSum
  items {
    product {
      rowId
      imageUrl: mainImage(size: 285)
      retinaImageUrl: mainImage(size: 570)
      counterInit
      counterStep
      price
      status
    }
  }
  ...OrderDetails_data
}
    ${OrderDetails_DataFragmentDoc}`;
export const ProductListItem_DataFragmentDoc = gql`
    fragment ProductListItem_data on Product {
  name
  imageUrl: mainImageUrl(size: 285)
  retinaImageUrl: mainImageUrl(size: 570)
  unit
  weight
  pageUrl
  price
  oldPrice
  ...CartCounter_data
}
    ${CartCounter_DataFragmentDoc}`;
export const Review_DataFragmentDoc = gql`
    fragment Review_data on Review {
  author
  url
  imageUrl: mainImageUrl(size: 285)
  retinaImageUrl: mainImageUrl(size: 570)
}
    `;
export const DepositTransaction_DataFragmentDoc = gql`
    fragment DepositTransaction_data on DepositTransaction {
  orderId
  value
  stepValue
  createdAt
}
    `;
export const MyDeposit_DataFragmentDoc = gql`
    fragment MyDeposit_data on User {
  depositBalance
  depositTransactions {
    ...DepositTransaction_data
  }
}
    ${DepositTransaction_DataFragmentDoc}`;
export const MyProfile_DataFragmentDoc = gql`
    fragment MyProfile_data on User {
  firstName
  lastName
  mainPhone
  email
}
    `;
export const Address_DataFragmentDoc = gql`
    fragment Address_data on Address {
  id
  rowId
  deleted
  city
  street
  house
  comment
  entrance
  building
  buildingFloor
  appartment
  intercomCode
}
    `;
export const MyAddressItem_DataFragmentDoc = gql`
    fragment MyAddressItem_data on Address {
  ...Address_data
}
    ${Address_DataFragmentDoc}`;
export const MyAddresses_DataFragmentDoc = gql`
    fragment MyAddresses_data on User {
  activeAddress {
    rowId
  }
  addresses {
    id
    rowId
    ...MyAddressItem_data
  }
}
    ${MyAddressItem_DataFragmentDoc}`;
export const ActiveAddress_DataFragmentDoc = gql`
    fragment ActiveAddress_data on User {
  activeAddress {
    rowId
    ...Address_data
  }
  addresses {
    rowId
  }
}
    ${Address_DataFragmentDoc}`;
export const My_DataFragmentDoc = gql`
    fragment My_data on User {
  firstName
  lastName
  mainPhone
  depositBalance
  email
  ...MyDeposit_data
  ...MyProfile_data
  ...MyAddresses_data
  ...ActiveAddress_data
}
    ${MyDeposit_DataFragmentDoc}
${MyProfile_DataFragmentDoc}
${MyAddresses_DataFragmentDoc}
${ActiveAddress_DataFragmentDoc}`;
export const AsyncCatalogDocument = gql`
    query AsyncCatalog {
  categories {
    rowId
  }
}
    `;

export function useAsyncCatalogQuery(options: Omit<Urql.UseQueryArgs<AsyncCatalogQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AsyncCatalogQuery>({ query: AsyncCatalogDocument, ...options });
};
export const AsyncCatalogContentDocument = gql`
    query AsyncCatalogContent {
  categories {
    rowId
    ...CategoryRow_data
  }
}
    ${CategoryRow_DataFragmentDoc}`;

export function useAsyncCatalogContentQuery(options: Omit<Urql.UseQueryArgs<AsyncCatalogContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AsyncCatalogContentQuery>({ query: AsyncCatalogContentDocument, ...options });
};
export const AuthViewerDocument = gql`
    query AuthViewer($token: String!) {
  viewer(token: $token) {
    user {
      ...My_data
    }
  }
}
    ${My_DataFragmentDoc}`;

export function useAuthViewerQuery(options: Omit<Urql.UseQueryArgs<AuthViewerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AuthViewerQuery>({ query: AuthViewerDocument, ...options });
};
export const AuthSignInDocument = gql`
    mutation AuthSignIn($token: String!, $input: SignInInput!) {
  result: signIn(token: $token, input: $input) {
    __typename
    ... on SignPayload {
      token
    }
    ... on ErrorPayload {
      message
    }
  }
}
    `;

export function useAuthSignInMutation() {
  return Urql.useMutation<AuthSignInMutation, AuthSignInMutationVariables>(AuthSignInDocument);
};
export const BannersDocument = gql`
    query Banners {
  banners(origin: "desktop") {
    rowId
    ...Banner_data
  }
}
    ${Banner_DataFragmentDoc}`;

export function useBannersQuery(options: Omit<Urql.UseQueryArgs<BannersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BannersQuery>({ query: BannersDocument, ...options });
};
export const ClosestDeliveryDocument = gql`
    query ClosestDelivery {
  deliveryIntervals {
    value
    from
    to
  }
  now
}
    `;

export function useClosestDeliveryQuery(options: Omit<Urql.UseQueryArgs<ClosestDeliveryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClosestDeliveryQuery>({ query: ClosestDeliveryDocument, ...options });
};
export const DeliveryCostsDocument = gql`
    query DeliveryCosts {
  deliveryZones {
    deliveryCosts {
      value
    }
    mapFillColor
    mapStrokeColor
  }
  deliveryCosts {
    itemSum
  }
}
    `;

export function useDeliveryCostsQuery(options: Omit<Urql.UseQueryArgs<DeliveryCostsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DeliveryCostsQuery>({ query: DeliveryCostsDocument, ...options });
};
export const DeliveryMapDocument = gql`
    query DeliveryMap {
  deliveryZones {
    bound {
      lat
      lng
    }
    deliveryCosts {
      itemSum
      value
    }
    mapFillColor
    mapStrokeColor
  }
}
    `;

export function useDeliveryMapQuery(options: Omit<Urql.UseQueryArgs<DeliveryMapQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<DeliveryMapQuery>({ query: DeliveryMapDocument, ...options });
};
export const HeaderCategorySelectorDocument = gql`
    query HeaderCategorySelector {
  categories {
    rowId
    pageUrl
    name
  }
}
    `;

export function useHeaderCategorySelectorQuery(options: Omit<Urql.UseQueryArgs<HeaderCategorySelectorQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HeaderCategorySelectorQuery>({ query: HeaderCategorySelectorDocument, ...options });
};
export const HomeCategoriesDocument = gql`
    query HomeCategories {
  categories {
    rowId
    ...CategoryRow_data
  }
}
    ${CategoryRow_DataFragmentDoc}`;

export function useHomeCategoriesQuery(options: Omit<Urql.UseQueryArgs<HomeCategoriesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HomeCategoriesQuery>({ query: HomeCategoriesDocument, ...options });
};
export const HomeReviewsDocument = gql`
    query HomeReviews {
  reviews(first: 15) {
    rowId
    ...Review_data
  }
}
    ${Review_DataFragmentDoc}`;

export function useHomeReviewsQuery(options: Omit<Urql.UseQueryArgs<HomeReviewsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<HomeReviewsQuery>({ query: HomeReviewsDocument, ...options });
};
export const MessageFormDocument = gql`
    mutation MessageForm($token: String, $input: CreateMessageInput!) {
  result: createMessage(token: $token, input: $input) {
    message
  }
}
    `;

export function useMessageFormMutation() {
  return Urql.useMutation<MessageFormMutation, MessageFormMutationVariables>(MessageFormDocument);
};
export const MyAddressesUpdateProfileDocument = gql`
    mutation MyAddressesUpdateProfile($token: String!, $input: ChangeProfileInput!) {
  result: changeProfile(token: $token, input: $input) {
    __typename
    ... on ErrorPayload {
      message
    }
    ... on User {
      rowId
      email
      firstName
      lastName
      mainPhone
      depositBalance
    }
  }
}
    `;

export function useMyAddressesUpdateProfileMutation() {
  return Urql.useMutation<MyAddressesUpdateProfileMutation, MyAddressesUpdateProfileMutationVariables>(MyAddressesUpdateProfileDocument);
};
export const MyAddressesDeleteAddressDocument = gql`
    mutation MyAddressesDeleteAddress($token: String!, $rowId: Int!) {
  deleteAddress(token: $token, rowId: $rowId) {
    __typename
    ... on ErrorPayload {
      message
    }
    ... on DeleteAddressPayload {
      viewer {
        user {
          id
          activeAddress {
            rowId
          }
          addresses {
            ...MyAddressItem_data
          }
        }
      }
    }
  }
}
    ${MyAddressItem_DataFragmentDoc}`;

export function useMyAddressesDeleteAddressMutation() {
  return Urql.useMutation<MyAddressesDeleteAddressMutation, MyAddressesDeleteAddressMutationVariables>(MyAddressesDeleteAddressDocument);
};
export const MyHistoryDocument = gql`
    query MyHistory($token: String!, $limit: Int) {
  viewer(token: $token) {
    user {
      orderCount
      orders(limit: $limit) {
        id
        ...MyHistoryOrder_data
      }
    }
  }
}
    ${MyHistoryOrder_DataFragmentDoc}`;

export function useMyHistoryQuery(options: Omit<Urql.UseQueryArgs<MyHistoryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MyHistoryQuery>({ query: MyHistoryDocument, ...options });
};
export const MyNewAddressDocument = gql`
    mutation MyNewAddress($token: String!, $input: AddressInput!) {
  result: createAddress(token: $token, input: $input) {
    __typename
    ... on ErrorPayload {
      message
    }
    ... on CreateAddressPayload {
      address {
        id
      }
      viewer {
        user {
          id
          activeAddress {
            ...Address_data
          }
          addresses {
            ...MyAddressItem_data
          }
        }
      }
    }
  }
}
    ${Address_DataFragmentDoc}
${MyAddressItem_DataFragmentDoc}`;

export function useMyNewAddressMutation() {
  return Urql.useMutation<MyNewAddressMutation, MyNewAddressMutationVariables>(MyNewAddressDocument);
};
export const MyPasswordDocument = gql`
    mutation MyPassword($token: String!, $input: ChangePasswordInput!) {
  result: changePassword(token: $token, input: $input) {
    __typename
    ... on ErrorPayload {
      message
    }
  }
}
    `;

export function useMyPasswordMutation() {
  return Urql.useMutation<MyPasswordMutation, MyPasswordMutationVariables>(MyPasswordDocument);
};
export const MyProfileDocument = gql`
    mutation MyProfile($token: String!, $input: ChangeProfileInput!) {
  result: changeProfile(token: $token, input: $input) {
    __typename
    ... on User {
      ...MyProfile_data
    }
    ... on ErrorPayload {
      message
    }
  }
}
    ${MyProfile_DataFragmentDoc}`;

export function useMyProfileMutation() {
  return Urql.useMutation<MyProfileMutation, MyProfileMutationVariables>(MyProfileDocument);
};
export const PersistStateDocument = gql`
    query PersistState($token: String) {
  viewer(token: $token) {
    token
    user {
      rowId
      buyedProductIds
    }
  }
}
    `;

export function usePersistStateQuery(options: Omit<Urql.UseQueryArgs<PersistStateQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PersistStateQuery>({ query: PersistStateDocument, ...options });
};
export const RecommendationsQueryDocument = gql`
    query RecommendationsQuery {
  viewer {
    recommendations {
      ...CategoryRow_data
    }
  }
}
    ${CategoryRow_DataFragmentDoc}`;

export function useRecommendationsQueryQuery(options: Omit<Urql.UseQueryArgs<RecommendationsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<RecommendationsQuery>({ query: RecommendationsQueryDocument, ...options });
};
export const SaleQueryDocument = gql`
    query SaleQuery {
  onSale {
    ...CategoryRow_data
  }
}
    ${CategoryRow_DataFragmentDoc}`;

export function useSaleQueryQuery(options: Omit<Urql.UseQueryArgs<SaleQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SaleQuery>({ query: SaleQueryDocument, ...options });
};
export const SubscribeFormDocument = gql`
    mutation SubscribeForm($token: String, $email: String!) {
  result: subscribe(token: $token, email: $email) {
    message
  }
}
    `;

export function useSubscribeFormMutation() {
  return Urql.useMutation<SubscribeFormMutation, SubscribeFormMutationVariables>(SubscribeFormDocument);
};
export const CartDocument = gql`
    query Cart($ids: [Int!]!) {
  products: productsByIds(ids: $ids) {
    rowId
    price
    oldPrice
    ...ProductListItem_data
  }
  deliveryCosts {
    itemSum
    ...CartSummary_data
  }
}
    ${ProductListItem_DataFragmentDoc}
${CartSummary_DataFragmentDoc}`;

export function useCartQuery(options: Omit<Urql.UseQueryArgs<CartQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CartQuery>({ query: CartDocument, ...options });
};
export const CheckoutQueryDocument = gql`
    query CheckoutQuery($token: String, $ids: [Int!]!) {
  viewer(token: $token) {
    token
    user {
      depositBalance
      mainPhone
      lastOrder {
        ecoPack
        withoutOverweight
        asap
        paymentMethod
      }
      ...ActiveAddress_data
    }
  }
  products: productsByIds(ids: $ids) {
    rowId
    price
    oldPrice
  }
  deliveryIntervals {
    value
    ...DeliveryTimePicker_interval
  }
  deliveryCosts {
    itemSum
    ...CartSummary_data
  }
}
    ${ActiveAddress_DataFragmentDoc}
${DeliveryTimePicker_IntervalFragmentDoc}
${CartSummary_DataFragmentDoc}`;

export function useCheckoutQueryQuery(options: Omit<Urql.UseQueryArgs<CheckoutQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CheckoutQuery>({ query: CheckoutQueryDocument, ...options });
};
export const CheckoutMutationDocument = gql`
    mutation CheckoutMutation($token: String!, $input: PlaceOrderInput!, $yandex: Boolean!, $clearCart: Boolean) {
  placeOrder(token: $token, input: $input, clearCart: $clearCart) {
    __typename
    ... on PlaceOrderPayload {
      orderId
      onlinePaymentUrl
      authCodeSended
      totalSum
      yandexKassaConfirmationToken @include(if: $yandex)
      viewer {
        token
        user {
          depositBalance
          mainPhone
          ...ActiveAddress_data
          lastOrder {
            ecoPack
            withoutOverweight
            asap
            paymentMethod
          }
        }
      }
    }
    ... on ErrorPayload {
      message
    }
  }
}
    ${ActiveAddress_DataFragmentDoc}`;

export function useCheckoutMutationMutation() {
  return Urql.useMutation<CheckoutMutation, CheckoutMutationVariables>(CheckoutMutationDocument);
};
export const CheckoutFinishModalDocument = gql`
    mutation CheckoutFinishModal($input: AttachOrderAndSignInInput!) {
  attachOrderAndSignIn(input: $input) {
    __typename
    ... on SignPayload {
      token
      viewer {
        token
        user {
          depositBalance
          mainPhone
          ...ActiveAddress_data
          lastOrder {
            ecoPack
            withoutOverweight
            asap
            paymentMethod
          }
        }
      }
    }
    ... on ErrorPayload {
      message
    }
  }
}
    ${ActiveAddress_DataFragmentDoc}`;

export function useCheckoutFinishModalMutation() {
  return Urql.useMutation<CheckoutFinishModalMutation, CheckoutFinishModalMutationVariables>(CheckoutFinishModalDocument);
};
export const ForgotPasswordRequestSignInCodeDocument = gql`
    mutation ForgotPasswordRequestSignInCode($token: String!, $input: RequestSignInCodeInput!) {
  result: requestSignInCode(token: $token, input: $input) {
    __typename
    ... on ErrorPayload {
      message
    }
  }
}
    `;

export function useForgotPasswordRequestSignInCodeMutation() {
  return Urql.useMutation<ForgotPasswordRequestSignInCodeMutation, ForgotPasswordRequestSignInCodeMutationVariables>(ForgotPasswordRequestSignInCodeDocument);
};
export const ForgotPasswordSignInByCodeDocument = gql`
    mutation ForgotPasswordSignInByCode($token: String!, $input: SignInByCodeInput!) {
  result: signInByCode(token: $token, input: $input) {
    __typename
    ... on SignPayload {
      token
    }
    ... on ErrorPayload {
      message
    }
  }
}
    `;

export function useForgotPasswordSignInByCodeMutation() {
  return Urql.useMutation<ForgotPasswordSignInByCodeMutation, ForgotPasswordSignInByCodeMutationVariables>(ForgotPasswordSignInByCodeDocument);
};
export const MySignOutDocument = gql`
    mutation MySignOut {
  signOut {
    token
  }
}
    `;

export function useMySignOutMutation() {
  return Urql.useMutation<MySignOutMutation, MySignOutMutationVariables>(MySignOutDocument);
};
export const ProductsPageDocument = gql`
    query ProductsPage($ids: [Int!]!) {
  productsByIds(ids: $ids) {
    rowId
    ...ProductCard_data
  }
}
    ${ProductCard_DataFragmentDoc}`;

export function useProductsPageQuery(options: Omit<Urql.UseQueryArgs<ProductsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProductsPageQuery>({ query: ProductsPageDocument, ...options });
};
export const RecommendationsPageDocument = gql`
    query RecommendationsPage {
  viewer {
    recommendations {
      products {
        ...ProductCard_data
      }
    }
  }
}
    ${ProductCard_DataFragmentDoc}`;

export function useRecommendationsPageQuery(options: Omit<Urql.UseQueryArgs<RecommendationsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<RecommendationsPageQuery>({ query: RecommendationsPageDocument, ...options });
};
export const ReviewsPageDocument = gql`
    query ReviewsPage {
  reviews {
    rowId
    ...Review_data
  }
}
    ${Review_DataFragmentDoc}`;

export function useReviewsPageQuery(options: Omit<Urql.UseQueryArgs<ReviewsPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ReviewsPageQuery>({ query: ReviewsPageDocument, ...options });
};
export const SalePageDocument = gql`
    query SalePage {
  onSale {
    products {
      ...ProductCard_data
    }
  }
}
    ${ProductCard_DataFragmentDoc}`;

export function useSalePageQuery(options: Omit<Urql.UseQueryArgs<SalePageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SalePageQuery>({ query: SalePageDocument, ...options });
};
export const SearchDocument = gql`
    query Search($search: String!) {
  products(search: $search) {
    rowId
    ...ProductCard_data
  }
}
    ${ProductCard_DataFragmentDoc}`;

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchQuery>({ query: SearchDocument, ...options });
};
export const BlockPageDocument = gql`
    query BlockPage($name: String!) {
  block(name: $name) {
    name
    data
  }
}
    `;

export function useBlockPageQuery(options: Omit<Urql.UseQueryArgs<BlockPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BlockPageQuery>({ query: BlockPageDocument, ...options });
};
export const CategoryPageDocument = gql`
    query CategoryPage($rowId: Int!) {
  category(rowId: $rowId) {
    rowId
    name
    products {
      rowId
      ...ProductCard_data
    }
  }
}
    ${ProductCard_DataFragmentDoc}`;

export function useCategoryPageQuery(options: Omit<Urql.UseQueryArgs<CategoryPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CategoryPageQuery>({ query: CategoryPageDocument, ...options });
};
export const ProductPageDocument = gql`
    query ProductPage($rowId: Int!) {
  product(rowId: $rowId) {
    name
    unit
    weight
    categories {
      name
      pageUrl
    }
    imageUrl: mainImageUrl(size: 584)
    retinaImageUrl: mainImageUrl(size: 1168)
    price
    oldPrice
    composition
    vendorCountry
    expiresIn
    storageRequirements
    ...CartCounter_data
  }
}
    ${CartCounter_DataFragmentDoc}`;

export function useProductPageQuery(options: Omit<Urql.UseQueryArgs<ProductPageQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProductPageQuery>({ query: ProductPageDocument, ...options });
};