declare global {
  interface Window {
    fbq: any;
    gtag: any;
    yaCounter: any;
  }
}

const make = (key: string) => (...args: any[]) => {
  // eslint-disable-next-line no-console
  console.log(key, ...args);

  if (window[key as keyof Window]) {
    window[key as keyof Window](...args);
  }
};

const fbq = make('fbq');
const gtag = make('gtag');

const ym = make('ym');
const yaId = 33509038;

const dataLayerPush = (data: any) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(data);

  // eslint-disable-next-line no-console
  console.log('dataLayerPush', data);
};

const rawCriteoPush = (...events: any[]) => {
  // eslint-disable-next-line no-console
  console.log('criteo push', ...events);

  if (typeof window === 'undefined') {
    return;
  }

  // @ts-ignore
  window.criteo_q = window.criteo_q || [];
  // @ts-ignore
  window.criteo_q.push(...events);
};

const criteoPush = (...events: any[]) => {
  rawCriteoPush({ event: 'setAccount', account: 51743 }, { event: 'setSiteType', type: 'm' });

  // @ts-ignore
  if (typeof window !== 'undefined' && window.currentEmail) {
    // @ts-ignore
    rawCriteoPush({ event: 'setEmail', email: window.currentEmail });
  }

  rawCriteoPush(...events);
};

const tmrPush = (data: any) => {
  // eslint-disable-next-line no-console
  console.log('tmr push', data);

  if (typeof window === 'undefined') {
    return;
  }

  // @ts-ignore
  window._tmr = window._tmr || []; // eslint-disable-line no-underscore-dangle
  // @ts-ignore
  window._tmr.push(data); // eslint-disable-line no-underscore-dangle
};

export const viewPage = (path: string) => {
  if (window.gtag) {
    // TODO: get track id from env
    window.gtag('config', 'UA-71223737-1', { page_path: path });
  }

  if (window.yaCounter) {
    window.yaCounter.hit(path);
  }

  if (window.fbq) {
    window.fbq('track', 'virtualPageview', {
      url: 'path',
    });
  }
};

export type AddToCartData = { rowId: number; price: number };
export const addToCart = (data: AddToCartData, quantity: number) => {
  gtag('event', 'addToCart', {
    value: data.price,
    ecomm_pagetype: 'home',
    ecomm_prodid: data.rowId,
    ecomm_totalvalue: data.price * quantity,
  });

  fbq('AddToCart', {
    currency: 'RUB',
    content_type: 'product',
    content_ids: [data.rowId],
    value: data.price,
  });

  ym(yaId, 'ADD_TO_CART');

  dataLayerPush({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'RUB',
      add: {
        products: [
          {
            id: data.rowId,
            price: data.price,
            quantity,
          },
        ],
      },
    },
  });
};

export const openCart = (data: { items: Array<{ productId: number }>; itemSum: number }) => {
  gtag('event', 'rem_open_cart', {
    ecomm_prodid: data.items.map((i) => i.productId),
    ecomm_pagetype: 'cart',
    ecomm_totalvalue: data.itemSum,
  });

  ym(yaId, 'OPEN_CART');
};

export type PurchaseData = {
  orderId: number;
  items: Array<{ productId: number; price: number; quantity: number }>;
  totalSum: number;
};

export const purchase = (data: PurchaseData) => {
  gtag('event', 'createOrder', {
    value: data.totalSum,
    ecomm_prodid: data.items.map((i) => i.productId),
    ecomm_pagetype: 'purchase',
    ecomm_totalvalue: data.totalSum,
  });

  // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
  fbq('Purchase', {
    currency: 'RUB',
    content_type: 'product',
    value: data.totalSum,
    contents: data.items.map((i) => ({
      id: i.productId,
      quantity: i.quantity,
    })),
  });

  dataLayerPush({
    ecommerce: {
      purchase: {
        actionField: {
          id: data.orderId,
          goal_id: 14741904,
          revenue: data.totalSum,
        },
        products: data.items.map((i) => ({
          id: i.productId,
          quantity: i.quantity,
          price: i.price,
        })),
      },
    },
  });

  criteoPush({
    event: 'trackTransaction',
    id: data.orderId,
    item: data.items.map((i) => ({
      id: i.productId,
      quantity: i.quantity,
      price: i.price,
    })),
  });

  tmrPush({
    type: 'itemView',
    productid: data.items.map((i) => i.productId),
    pagetype: 'purchase',
    list: '1',
    totalvalue: data.totalSum,
  });
};

export const viewProduct = (data: { rowId: number; name: string; price: number; category: string }) => {
  dataLayerPush({
    ecommerce: {
      detail: {
        products: [
          {
            id: data.rowId,
            name: data.name,
            price: data.price,
            category: data.category,
          },
        ],
      },
    },
  });
};

export const clickOnAppBanner = () => gtag('event', 'clickOnAppBanner');
